import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import styled from 'styled-components'
import SEO from "../components/seo"
import PageIntro from "../components/PageIntro"
import ContactForm from "../components/ContactForm"

export const ContactPageTemplate = ({
  page_intro,
  children,
  isPreview = false
}) => (
  <ContactWrapper>
    <section className="page-header">
      <div className="container">
        <div className="inner-container">
          <div className="columns">
            <div className="column column-6">
              <PageIntro {...page_intro} />
            </div>
            <div className="column content-b">
              <div className="contact-us-form-container">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ContactWrapper>
)

const Contact = ({ data: { markdownRemark: { frontmatter }}}) => {
  return (
    <Layout className="contact">
      <SEO title={"Contact"} />
      <ContactPageTemplate {...frontmatter}>
        <ContactForm />
      </ContactPageTemplate>
    </Layout>
  )
}

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: rgba(53, 163, 162, 0.05);

  .page-header {
    padding: 2.5rem 0;

    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0;
    }

    .content-b {
      .contact-us-form-container {
        margin-top: 1.5rem;
       
        @media (min-width: ${({ theme }) => theme.desktop}) {
          max-width: 382px;
          float: right;
          margin-top: 0;
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        page_intro {
          heading
          subheading
          subtitle
          text
        }
      }
    }
  }
`

export default Contact