import React from "react"
import styled from "styled-components"

const ContactForm = () => {
  return (
    <ContactFormWrapper>
      <h2>Contact us</h2>
      <form name="contact-us" action="/thank-you/" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact-us" />
        <input type="text" name="name" placeholder="Name" aria-label="Name" required/>
        <input type="text" name="email" placeholder="Email" aria-label="Email Address" required/>
        <input type="text" name="reason" placeholder="What can we help you with?" aria-label="What can we help you with?"/>
        <textarea type="textarea" name="additional" placeholder="Additional information" aria-label="Additional Information"/>
        <button type="submit" className="button solid" value="Contact Us" aria-label="Submit the Contact Form">Contact Us</button>
      </form>
    </ContactFormWrapper>
  )
}

const ContactFormWrapper = styled.div`
  border-radius: 12px;
  background: white;
  padding: 2rem 2.5rem 2.5rem;
  box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.06);

  h2 {
    ${({ theme }) => theme.isHeadingSize['1']};
  }

  form {
    input[type="text"], textarea {
      width: 100%;
      height: 2.25rem;
      font-family: ${({ theme }) => theme.fonts['roboto']};
      font-size: 0.75rem;
      line-height: 2;
      letter-spacing: 0.3px;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      margin: 0.75rem 0 0;
      color: ${({ theme }) => theme.grey2};
      border: 1px solid #dddee0;

      &::placeholder {
        color: ${({ theme }) => theme.grey1};
      }
    }

    textarea {
      height: 4.5rem;
      line-height: 1.4;
      padding-top: 0.625rem;
    }

    button[type="submit"] {
      width: auto;
      -webkit-appearance: button;
      margin: 1rem 0 0;
      padding: 0 1.5rem;
    }
  }
`

export default ContactForm