import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import ReactMarkdown from "react-markdown"
import CustomLink from "../components/CustomLink"

const PageIntro = ({
  heading,
  subheading,
  subtitle,
  text,
  buttons
}) => {
  return (
    <Wrapper className="content page-intro">
      {heading && <h1>{heading}</h1>}
      {subheading && <h2 className="subheading">{subheading}</h2>}
      {subtitle && <div className="large"><ReactMarkdown source={subtitle} /></div>}
      {text && <ReactMarkdown source={text} />}
      {buttons && (
        <div className="page-intro__actions">
          {map(buttons, (item, index) => (
            <CustomLink
              key={`page-intro-button-${index}`}
              className={`button ${item.class}`}
              href={item.href}
            >
              {item.text}
            </CustomLink>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &, .page-intro {

    h1 {
      ${({ theme }) => theme.isHeadingSize['3']};
      margin-bottom: 1.875rem;
    }

    .subheading {
      ${({ theme }) => theme.isHeadingSize['1']};
    }

    &__actions {
      margin-top: 2.5rem;

      .button:first-child {
        margin-left: 0;
        margin-bottom: 0.625rem;
        @media (min-width: ${({ theme }) => theme.desktop}) {
          margin-bottom: 0;
        }
      }
    }
  }
  
`

export default PageIntro
